
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ClarityCity-Regular';
    src: local('ClarityCity-Regular'), 
    url("./fonts/ClarityCity-Regular.ttf") format("truetype");
    font-weight: normal;
  }
  * {
    box-sizing: border-box;
  }
  
  #modal-container:target {
    opacity: 1;
  }
  
  body {
    margin: 0;
    font-family: ClarityCity-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  .modal {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .modal-content {
    min-width: 300px;
    max-width: 500px;
    width: auto;
    background-color: #fff;
    height: auto;
    margin-top: 10px;
  }
  .modal-body {
    padding: 10px;
  }